
  <footer style="overflow: hidden;">
    <div class="row justify-content-around mb-0 pt-5 pb-4 " style="background-color: #464646; color: white;">
      <div class=" col-11">
        <div class="row justify-content-center">
          <div class="col-md-3 col-12 font-italic align-items-center mt-md-3 mt-4 text-center">
             <img (click)="routingCall()" src="/assets/logo.JPG" style="height: 150px; width: 150px;"/>
             <p class="social mt-md-3 mt-2"> <span (click)="routingCall()"><i class="fab fa-twitter" ></i></span> &nbsp;&nbsp;
              <span (click)="routingCall()"><i class="fab fa-linkedin-in" ></i></span>
              &nbsp;&nbsp;<span (click)="routingCall()"> <i class="fab fa-facebook-f" ></i></span>

            </p> <small class="copy-rights ">&#9400; Buero Planet</small><br> <small>Copyright.All Rights Reserved. </small>
          </div>
          <div class="col-md-3 col-12 my-sm-0 mt-5">
            <ul class="list-unstyled text-center">
              <li class="mt-md-3 mt-4"><span class="footerLinks cursorpointer">Our Products</span></li>
              <li><a (click)="routingCall()" class="footerLinks cursorpointer">Software</a></li>
              <li><a (click)="routingCall()" class="footerLinks cursorpointer">Hardware</a></li>
              <li><a (click)="routingCall()" class="footerLinks cursorpointer">Buchsotichment</a></li>
              <li><a (click)="routingCall()" class="footerLinks cursorpointer">Root</a></li>
              <li><a (click)="routingCall()" class="footerLinks cursorpointer">Music</a></li>
            </ul>
          </div>
          <div class="col-md-3 col-12 my-sm-0 mt-5">
            <ul class="list-unstyled text-center">
              <li class="mt-md-3 mt-4">&nbsp;</li>
              <li><a (click)="routingCall()" class="footerLinks cursorpointer">IT Hardware</a></li>
              <li><a (click)="routingCall()" class="footerLinks cursorpointer">IT Software</a></li>
              <li><a (click)="routingCall()" class="footerLinks cursorpointer">Mobiles</a></li>
              <li><a (click)="routingCall()" class="footerLinks cursorpointer">Laptops</a></li>

            </ul>
          </div>
          <div class="col-xl-auto col-md-3 col-12 my-sm-0 mt-5">
            <ul class="list-unstyled text-center">
              <li class="mt-md-3 mt-4" class="footerLinks"><span class="footerLinks">Quick Links</span></li>
              <li><a (click)="routingCall()" class="footerLinks cursorpointer">Account</a></li>
              <li><a (click)="routingCall()" class="footerLinks cursorpointer">Term & Condition</a></li>
              <li><a (click)="routingCall()" class="footerLinks cursorpointer">Contact</a></li>
              <li><a (click)="routingCall()" class="footerLinks cursorpointer">Privacy policy</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
<div class="container-fluid p-0 bg-light" style="overflow: hidden;margin-top: 0;">
      <div class="container">
          <div class="row p-3" >
                  <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 pt-4 text-center">
                        <b>SIGN UP FOR NEWSLETTER</b>
                  </div>
                  <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 d-flex text-center pt-4">
                   <input type="email" placeholder="Enter Email..." class="form-control" />
                   <button class="btn" style="background-color:  #ED1B24; color: whitesmoke;height: 38px;">Submit</button>
                  </div>
                  <div class="col-md-4 col-lg-4 col-sm-12 col-xs-12 text-center pt-2">
                           <p style="font-weight: 600; cursor: pointer;" (click)="routingCall()">Info@bueroplanet.com</p>
                           <p  style="font-weight: 600; cursor: pointer;" (click)="routingCall()">help@bueroplanet.com</p>
                  </div>
          </div>
      </div>
</div>
